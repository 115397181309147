import { IonContent, IonHeader, IonIcon, IonLabel, IonPage, IonSpinner, IonToolbar } from '@ionic/react';
import { checkmarkCircle, checkmarkCircleOutline } from 'ionicons/icons';
import { useEffect, useRef, useState } from 'react';
// @ts-ignore
// eslint-disable-next-line
import mapboxgl from '!mapbox-gl';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import './Home.css';
import CpsApiHelper from '../classes/CpsApiHelper';
import MapboxHelper from '../classes/MapboxHelper';
import ReactDOM from 'react-dom';
import MapChargerPopup from '../components/MapChargerPopup';

const Home: React.FC = () => {

  const map = useRef<mapboxgl.Map>(undefined);
  const [chargepointDetails, setChargepointDetails] = useState();
  const [chargepointStatuses, setChargepointStatuses] = useState();

  const [chargepointMapClickEventStatus, setChargepointMapClickEventStatus] = useState(false);

  // Run on first load
  useEffect(() => {

    // Setup mapbox map
    mapboxgl.accessToken = 'pk.eyJ1IjoieGluZ2JyZWFraW4iLCJhIjoiY2pqOXQyZTBwMGluZTNxbnZobWlxN29xNCJ9.Zt1lnaq3bodiPNQpL879wQ';
    map.current = new mapboxgl.Map({
      container: 'map', // container ID
      style: 'mapbox://styles/xingbreakin/cl4x9qn7i000714qlohlseoqv', // style URL
      center: [-4.14, 55.42], // starting position [lng, lat]
      zoom: 5 // starting zoom
    });

    // Resize map immediately after style has first loaded
    map.current.on('style.load', () => {
      // Resize map to fit window
      map.current.resize();
    });

    // Add navigation controls to map
    map.current.addControl(new mapboxgl.NavigationControl({
      showCompass: true,
      showZoom: true,
      visualizePitch: true
    }), "bottom-right");

    // Add geolocation controls to the map.
    map.current.addControl(
      new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true
        },
        trackUserLocation: true,
        showUserHeading: true,
        showAccuracyCircle: true
      }), "bottom-right"
    );

    // Add geocoding controls to the map.
    // map.current.addControl(
    //   new MapboxGeocoder({
    //     accessToken: mapboxgl.accessToken,
    //     mapboxgl: mapboxgl,
    //     placeholder: 'Search for a location',
    //     marker: false,
    //     countries: 'GB',
    //     language: 'en',
    //   }), "top-left"
    // );

    // Center map on user location
    map.current.on('load', () => {
      navigator.geolocation.getCurrentPosition((position) => {
        map.current.setCenter([position.coords.longitude, position.coords.latitude]);
        map.current.setZoom(13);
      }, (error) => {
        console.log("Could not get user location");
      }, {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      });
    });


    // cleanup function to remove map on unmount
    return () => {
      map.current.remove()
    }
  }, []);

  // Run on chargepointDetails change
  useEffect(() => {
    if (chargepointDetails === undefined) {
      (async () => {
        await updateChargepointDetails(setChargepointDetails);
        map.current.resize();
      })();
    } else {
      addChargepointDataToMap(map.current, chargepointDetails);
    }
  }, [chargepointDetails]);

  // Run on chargePointStatus change
  useEffect(() => {
    // If not retrieved yet, retrieve chargepoint status data from CPS API
    if (chargepointStatuses === undefined) {
      (async () => {
        await updateChargepointStatuses(setChargepointStatuses);
      })();
    }
  }, [chargepointStatuses]);

  // Run on chargePointStatus or chargepointMapClickEventStatus change
  useEffect(() => {
    // If chargepoint status data retrieved from CPS API
    if (chargepointStatuses !== undefined) {
      // If not already handled, handle charger feature map click events
      if (chargepointMapClickEventStatus === false) {
        MapboxHelper.mapClickHandler(map.current, ['chargepoints-layer'], (clickedChargepointsDetails) => {
          // Sort chargepoints by chargepoint connector count
          clickedChargepointsDetails.sort((a: any, b: any) => {
            let input1 = JSON.parse(a.properties.connectorGroups).length;
            let input2 = JSON.parse(b.properties.connectorGroups).length;
            return input2 - input1;
          });
          // Get charger status data for each clicked chargepoint
          let clickedChargepointsStatuses: any[] = [];
          clickedChargepointsDetails.forEach((chargepoint: any, index: number) => {
            clickedChargepointsStatuses.push(CpsApiHelper.findChargepointStatus(chargepointStatuses, clickedChargepointsDetails[index].properties.id));
          });
          // Define and render popup with charger data
          const popupNode = document.createElement("div")
          ReactDOM.render(
            <MapChargerPopup clickedChargepointsDetails={clickedChargepointsDetails} clickedChargepointsStatuses={clickedChargepointsStatuses} />,
            popupNode
          );
          // Add popup to map
          MapboxHelper.addPopup(map.current, clickedChargepointsDetails[0].geometry.coordinates, popupNode);
        });
        setChargepointMapClickEventStatus(true);
      }
    }
  }, [chargepointStatuses, chargepointMapClickEventStatus]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <img id='header-logo' alt='CPS Logo' src='/assets/images/logos/ABCPIconText.svg' onClick={() => window.location.href = 'https://chargeplacescotland.org/'}></img>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div id='map-loading-container' className={chargepointDetails !== undefined && chargepointStatuses !== undefined ? "map-loading-container-hidden" : ""}>
          <IonSpinner name='crescent'></IonSpinner>
          <IonLabel>Loading Map</IonLabel>
          <div id='map-loading-progress-container'>
            <div className={chargepointStatuses !== undefined ? "map-loading-progress-item map-loading-progress-item-success" : "map-loading-progress-item"}>
              <IonIcon src={chargepointStatuses !== undefined ? checkmarkCircle : checkmarkCircleOutline}></IonIcon>
              <IonLabel>Retrieving charger status...</IonLabel>
            </div>
            <div className={chargepointDetails !== undefined ? "map-loading-progress-item map-loading-progress-item-success" : "map-loading-progress-item"}>
              <IonIcon src={chargepointDetails !== undefined ? checkmarkCircle : checkmarkCircleOutline}></IonIcon>
              <IonLabel>Retrieving charger details...</IonLabel>
            </div>
          </div>
        </div>
        <div id="map" className={chargepointDetails === undefined || chargepointStatuses === undefined ? "map-hidden" : ""}></div>
      </IonContent>
    </IonPage>
  );
};

const updateChargepointDetails = async (setChargepointDetails: any) => {
  const data = await CpsApiHelper.getFilteredChargepointDetails();
  setChargepointDetails(data);
}

const updateChargepointStatuses = async (setChargepointStatuses: any) => {
  const data = await CpsApiHelper.getAllChargepointStatuses();
  setChargepointStatuses(data);
}

const addChargepointDataToMap = (map: mapboxgl.Map, chargepointDetails: any) => {
  // Add chargepoint data to map
  MapboxHelper.addCircleLayer(map, "chargepoints", chargepointDetails);
}


export default Home;

