
class CpsApiHelper {

    private static API_ROOT: string = "https://account.chargeplacescotland.org/";

    // Perform a get request to the CPS servers
    private static async getRequest(apiEndpoint: string) {

        const response = await fetch(this.API_ROOT + apiEndpoint, {
            method: 'GET',
            cache: 'no-cache',
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            headers: {
                'Content-Type': 'application/json',
                'api-auth': 'c3VwcG9ydCtjcHNhcHBAdmVyc2FudHVzLmNvLnVrOmt5YlRYJkZPJCEzcVBOJHlhMVgj',
            },
        });

        return response.json();
    }

    // Get details for all charge points on CPS network in GeoJSON format
    public static async getAllChargepointDetails() {
        let responseJSON = await this.getRequest("api/v3/poi/chargepoint/static");
        // Server returns lat / lng in wrong order, rectify this here
        responseJSON.features.forEach((element: any) => {
            let initialLat = element.geometry.coordinates[0];
            let initialLng = element.geometry.coordinates[1];
            element.geometry.coordinates[0] = initialLng;
            element.geometry.coordinates[1] = initialLat;
        });
        console.log(responseJSON);
        return responseJSON;
    }

    // Get charger status for all charge points on CPS network
    public static async getAllChargepointStatuses() {
        let responseJSON = await this.getRequest("api/v2/poi/chargepoint/dynamic");
        // console.log(responseJSON);
        return responseJSON;
    }

    // Get details for charge points on CPS network in GeoJSON format based on current filter settings
    public static async getFilteredChargepointDetails() {
        // Get all charge points on CPS network
        let allChargepointDetails = await this.getAllChargepointDetails();

        // create an empty geoJSON FeatureCollection
        let filteredChargepointDetails : any;
        filteredChargepointDetails = {
            type: "FeatureCollection",
            features: []
        }

        // Loop through all charge points on CPS network
        allChargepointDetails.features.forEach((chargepoint : any) => {
            
            // Get connector categories for current charge point (Rapid,Fast,Slow)
            let isRapidChargepoint = chargepoint.properties.connectorGroups.find((connectorGroup: any) => connectorGroup.connectors[0].connectorMaxChargeRate >= 50);
            let isFastChargepoint = chargepoint.properties.connectorGroups.find((connectorGroup: any) => connectorGroup.connectors[0].connectorMaxChargeRate < 50 && connectorGroup.connectors[0].connectorMaxChargeRate > 7);
            let isSlowChargePoint = chargepoint.properties.connectorGroups.find((connectorGroup: any) => connectorGroup.connectors[0].connectorMaxChargeRate <= 7);

            // Get connector types for current charge point (CCS, Type 2, CHAdeMO)
            let isCCS = chargepoint.properties.connectorGroups.find((connectorGroup: any) => connectorGroup.connectors[0].connectorPlugType === "ccs");
            let isType2 = chargepoint.properties.connectorGroups.find((connectorGroup: any) => connectorGroup.connectors[0].connectorPlugType === "type_2_plug");
            let isCHAdeMO = chargepoint.properties.connectorGroups.find((connectorGroup: any) => connectorGroup.connectors[0].connectorPlugType === "chademo");
        
            // if(isRapidChargepoint && isCCS) {
                filteredChargepointDetails.features.push(chargepoint);
            // }
        });
        return filteredChargepointDetails;
    }


    // Get overall charger status for a specified charge point
    public static findChargepointStatus(chargepointStatuses: any, chargepointId: string) {
        // Check if chargepointId is in chargepointStatuses
        let result = chargepointStatuses.chargePoints.find((point: any) => point.chargePoint.id === chargepointId)
        if (result) {
            return result;
        }
    }

    // Get individual connector status for a specified charge point
    public static findConnectorStatus(chargepointConnectorStatuses: any, connectorGroupID: number) {
        let connectorStatus = chargepointConnectorStatuses.find((connector: any) => connector.connectorGroupID === connectorGroupID);
        return connectorStatus;
    }

}

export default CpsApiHelper;