import { IonButton, IonContent, IonIcon, IonPopover } from '@ionic/react';
import { arrowDownCircle, arrowUpCircle, chevronUp } from 'ionicons/icons';
import { useEffect, useRef, useState } from 'react';
import CpsApiHelper from '../classes/CpsApiHelper';
import './MapChargerPopup.css';

interface MapChargerPopupProps {
  clickedChargepointsDetails: any;
  clickedChargepointsStatuses: any;
}

const MapChargerPopup: React.FC<MapChargerPopupProps> = ({ clickedChargepointsDetails, clickedChargepointsStatuses }) => {

  const [deviceCount, setDeviceCount] = useState(0);

  const [type2Connections, setType2Connections] = useState(0);
  const [ccsConnections, setCcsConnections] = useState(0);
  const [chademoConnections, setChademoConnections] = useState(0);

  const [availableType2Connections, setAvailableType2Connections] = useState(0);
  const [availableCcsConnections, setAvailableCcsConnections] = useState(0);
  const [availableChademoConnections, setAvailableChademoConnections] = useState(0);

  const [noTariff, setNoTariff] = useState(false);
  const [identicalTariffs, setIdenticalTariffs] = useState(true);

  const [showScrollToTopButton, setShowScrollToTopButton] = useState(false);
  const [chargepointsExpanded, setChargepointsExpanded] = useState(false);

  const popupContainerDom = useRef<HTMLDivElement>(null);
  const expandChargersBtnDom = useRef<HTMLIonButtonElement>(null);

  useEffect(() => {
    if (clickedChargepointsDetails !== undefined) {
      setDeviceCount(clickedChargepointsDetails.length);
    }
  }, [clickedChargepointsDetails]);

  useEffect(() => {
    if (clickedChargepointsDetails !== undefined && clickedChargepointsStatuses !== undefined) {
      // Get number of available charge connectors by chargepoint type
      let type2Connections = 0;
      let ccsConnections = 0;
      let chademoConnections = 0;

      let availableType2Connections = 0;
      let availableCcsConnections = 0;
      let availableChademoConnections = 0;

      clickedChargepointsDetails.forEach((chargepoint: any, i: number) => {
        let connectorGroups = JSON.parse(chargepoint.properties.connectorGroups);
        connectorGroups.forEach((connectorGroup: any, j: number) => {
          let connectorType = connectorGroup.connectors[0].connectorPlugType
          let chargepointStatus = clickedChargepointsStatuses[i].chargePoint.connectorGroups[j].connectors[0].connectorStatus;
          if (connectorType === 'type_2_plug') {
            if (chargepointStatus === 'AVAILABLE') {
              availableType2Connections++;
            }
            type2Connections++;
          }
          if (connectorType === 'ccs') {
            if (chargepointStatus === 'AVAILABLE') {
              availableCcsConnections++;
            }
            ccsConnections++;
          }
          if (connectorType === 'chademo') {
            if (chargepointStatus === 'AVAILABLE') {
              availableChademoConnections++;
            }
            chademoConnections++;
          }
        });
      });

      setType2Connections(type2Connections);
      setCcsConnections(ccsConnections);
      setChademoConnections(chademoConnections);

      setAvailableType2Connections(availableType2Connections);
      setAvailableCcsConnections(availableCcsConnections);
      setAvailableChademoConnections(availableChademoConnections);


      // Check if all chargepoints have the same tariff
      let identicalTariffs = true;
      if (clickedChargepointsDetails.length > 1) {
        let baseTariff = JSON.parse(clickedChargepointsDetails[0].properties.tariff).description;
        clickedChargepointsDetails.forEach((chargepoint: any, i: number) => {
          let tariff = JSON.parse(chargepoint.properties.tariff).description;
          if (tariff !== baseTariff) {
            identicalTariffs = false;
          }
        });
      }
      setIdenticalTariffs(identicalTariffs);

      // Check if all chargepoints have no tariff
      let noTariff = false;
      if (identicalTariffs) {
        if (JSON.parse(clickedChargepointsDetails[0].properties.tariff).description.length === 0) {
          noTariff = true;
        }
      }
      setNoTariff(noTariff);
    }
  }, [clickedChargepointsDetails, clickedChargepointsStatuses]);

  // If chargepoints are expanded, view should be scrolled down appropriately
  useEffect(() => {
    if (chargepointsExpanded) {
      setTimeout(() => {
        scrollToExpandButton();
      }, 50);
    }
  }, [chargepointsExpanded]);

  // Scroll to specified position
  const scrollToPos = (pos: number) => {
    if (popupContainerDom.current !== null) {
      popupContainerDom.current.scroll({
        top: pos,
        left: 0,
        behavior: 'smooth'
      });
    }
  }

  // Scroll to expand button
  const scrollToExpandButton = () => {
    if (popupContainerDom.current !== null && expandChargersBtnDom.current !== null) {
      popupContainerDom.current.scrollTo({
        top: expandChargersBtnDom.current.offsetTop - 10,
        left: 0,
        behavior: 'smooth'
      }
      );
    }
  }

  // Handle popup scroll event
  const handleScroll = (event: any) => {
    if (event.currentTarget.scrollTop > 290) {
      if (!showScrollToTopButton) {
        setShowScrollToTopButton(true);
      }
    } else {
      if (showScrollToTopButton) {
        setShowScrollToTopButton(false);
      }
    }
  }

  return (
    <>
      <IonButton id='map-popup-scroll-top-button' shape='round' size='small' title='Scroll to top' mode='md' hidden={!showScrollToTopButton} onClick={() => scrollToPos(0)}>
        <IonIcon icon={chevronUp} slot='icon-only' />
      </IonButton>
      <div className='map-popup-container' ref={popupContainerDom} onScroll={handleScroll}>
        <div className='map-popup-container-header'>
          <div className='map-popup-header-heading'>
            <img src='/assets/images/evStation.svg' />
            <h2>{deviceCount} Charge Station{deviceCount === 1 ? '' : 's'}</h2>
          </div>
          <div className='map-popup-header-heading map-popup-header-tariff'>
            <span>Tariff:</span>
            {identicalTariffs ?
              <span>{JSON.parse(clickedChargepointsDetails[0].properties.tariff).description}</span>
              :
              <span><a onClick={() => { setChargepointsExpanded(true) }}>Multiple</a> tariffs at this location</span>
            }
            {noTariff ?
              <span>All chargers at this location are free to use.</span>
              :
              null
            }
          </div>
          {type2Connections > 0 ?
            <div className='map-popup-header-stat-container'>
              <div className='map-popup-header-stat-key'>
                <img src='/assets/images/type2.svg' alt='type_2_plug' />
                <div>Type 2 (AC)<br />connectors</div>
              </div>
              <div className='map-popup-header-stat-separator' />
              <div className={availableType2Connections ? 'map-popup-header-stat-value connector-available' : 'map-popup-header-stat-value connector-unavailable'}>
                <div>{availableType2Connections}</div>
                <div>Available</div>
              </div>
              <div className='map-popup-header-stat-separator' />
              <div className='map-popup-header-stat-value'>
                <div>{type2Connections}</div>
                <div>Total</div>
              </div>
            </div>
            : null
          }
          {ccsConnections > 0 ?
            <div className='map-popup-header-stat-container'>
              <div className='map-popup-header-stat-key'>
                <img src='/assets/images/ccs.svg' alt='ccs' />
                <div>CCS (DC)<br />connectors</div>
              </div>
              <div className='map-popup-header-stat-separator' />
              <div className={availableCcsConnections ? 'map-popup-header-stat-value connector-available' : 'map-popup-header-stat-value connector-unavailable'}>
                <div>{availableCcsConnections}</div>
                <div>Available</div>
              </div>
              <div className='map-popup-header-stat-separator' />
              <div className='map-popup-header-stat-value'>
                <div>{ccsConnections}</div>
                <div>Total</div>
              </div>
            </div>
            : null
          }
          {chademoConnections > 0 ?
            <div className='map-popup-header-stat-container'>
              <div className='map-popup-header-stat-key'>
                <img src='/assets/images/chademo.svg' alt='chademo' />
                <div>Chademo (DC)<br />connectors</div>
              </div>
              <div className='map-popup-header-stat-separator' />
              <div className={availableChademoConnections ? 'map-popup-header-stat-value connector-available' : 'map-popup-header-stat-value connector-unavailable'}>
                <div>{availableChademoConnections}</div>
                <div>Available</div>
              </div>
              <div className='map-popup-header-stat-separator' />
              <div className='map-popup-header-stat-value'>
                <div>{chademoConnections}</div>
                <div>Total</div>
              </div>
            </div>
            : null
          }
        </div>
        <IonButton shape='round' ref={expandChargersBtnDom} onClick={() => setChargepointsExpanded(!chargepointsExpanded)} className='map-popup-container-expand-button'>
          <IonIcon slot='start' src={chargepointsExpanded ? arrowUpCircle : arrowDownCircle} />
          <div>{chargepointsExpanded ? 'Hide Charge Stations' : "Show Charge Stations"}</div>
        </IonButton>
        {chargepointsExpanded ?
          clickedChargepointsDetails.map((chargepointDetails: any, index: number) =>
            <MapChargerPopupDevice key={index} chargepointDetails={chargepointDetails} chargepointStatus={clickedChargepointsStatuses[index]} showTariffs={!identicalTariffs} />
          )
          :
          <></>
        }
      </div>
    </>
  );
};


interface MapChargerPopupDeviceProps {
  chargepointDetails: any;
  chargepointStatus: any;
  showTariffs: boolean;
}

const MapChargerPopupDevice: React.FC<MapChargerPopupDeviceProps> = ({ chargepointDetails, chargepointStatus, showTariffs }) => {

  // Define state variables
  const [chargepointName, setChargepointName] = useState();
  const [chargepointUUID, setChargepointUUID] = useState();
  const [chargepointId, setChargepointId] = useState();

  const [chargepointTariffAmount, setChargepointTariffAmount] = useState(); // Currently unused as data is inconsistent
  const [chargepointTariffConnectionFee, setChargepointTariffConnectionFee] = useState(); // Currently unused as data is inconsistent
  const [chargepointTariffDetails, setChargepointTariffDetails] = useState();

  const [chargepointConnectorStatuses, setChargepointConnectorStatuses] = useState([]);
  const [chargepointConnectorDetails, setChargepointConnectorDetails] = useState([]);

  // Run when chargepointDetails or chargepointStatus changes
  useEffect(() => {

    // Set chargepoint name
    let chargepointAddress = JSON.parse(chargepointDetails.properties.address);
    let chargepointName = chargepointAddress.streetnumber;
    if (chargepointName === "") {
      chargepointName = chargepointAddress.street;
    }
    setChargepointName(chargepointName);

    // Set chargepoint UUID
    let chargepointUUID = chargepointDetails.properties.id;
    setChargepointUUID(chargepointUUID);

    // Set chargepoint Id
    let chargepointId = chargepointDetails.properties.name;
    setChargepointId(chargepointId);

    // Set chargepoint tariff
    let chargepointTariff = JSON.parse(chargepointDetails.properties.tariff);
    if (chargepointTariff.description.length === 0) {
      chargepointTariff.description = "Free to use";
    }
    setChargepointTariffAmount(chargepointTariff.amount);
    setChargepointTariffConnectionFee(chargepointTariff.connectionfee);
    setChargepointTariffDetails(chargepointTariff.description);
    console.log(chargepointTariff);

    // Set chargepoint connector details
    let chargepointConnectorDetails = JSON.parse(chargepointDetails.properties.connectorGroups);
    setChargepointConnectorDetails(chargepointConnectorDetails);

    // set chargepoint connector statuses
    let chargepointConnectorStatuses = chargepointStatus.chargePoint.connectorGroups;
    setChargepointConnectorStatuses(chargepointConnectorStatuses);

  }, [chargepointDetails, chargepointStatus]);

  return (
    <div className='map-popup-container-device'>
      <h3>{chargepointName}</h3>
      <h4>{chargepointId}</h4>
      {showTariffs ?
        <div className='map-popup-container-tariff'>
          {/* Turned off for now, data in API not accurate enough to rely on */}
          {/* <div className='map-popup-container-tariff-item'>
          <span>Price / kWh:</span>
          <span>{chargepointTariffAmount === null ? 'Free' : '£' + chargepointTariffAmount}</span>
        </div>
        <div className='map-popup-container-tariff-item'>
          <span>Connection Fee:</span>
          <span>{chargepointTariffConnectionFee === null ? 'None' : '£' + chargepointTariffConnectionFee}</span>
        </div> */}
          <div className='map-popup-container-tariff-item'>
            <span>Tariff:</span>
            <span>{chargepointTariffDetails}</span>
          </div>
        </div>
        :
        <></>
      }
      <div className='map-popup-container-connectors'>
        {chargepointConnectorDetails.map((individualConnectorDetails: any, index: number) =>
          <MapChargerPopupConnector key={individualConnectorDetails.connectorGroupID} connectorDetails={individualConnectorDetails.connectors[0]} connectorStatus={CpsApiHelper.findConnectorStatus(chargepointConnectorStatuses, individualConnectorDetails.connectorGroupID).connectors[0]} />
        )}
      </div>
    </div>
  );
};



interface MapChargerPopupConnectorProps {
  connectorDetails: any;
  connectorStatus: any;
}

const MapChargerPopupConnector: React.FC<MapChargerPopupConnectorProps> = ({ connectorDetails, connectorStatus }) => {

  // Define state variables
  const [plugTypeIcon, setPlugTypeIcon] = useState('/assets/images/type2.svg');
  const [chargeStatusCssClass, setChargeStatusCssClass] = useState('charge-status-available');

  useEffect(() => {
    // Set charge status css
    if (connectorStatus.connectorStatus === "AVAILABLE") {
      setChargeStatusCssClass('charge-status-available');
    } else if (connectorStatus.connectorStatus === "OCCUPIED") {
      setChargeStatusCssClass('charge-status-occupied');
    } else if (connectorStatus.connectorStatus === "UNAVAILABLE") {
      setChargeStatusCssClass('charge-status-unavailable');
    } else {
      setChargeStatusCssClass('charge-status-unknown');
    }
  }, [connectorStatus]);

  useEffect(() => {
    // Set charge status css
    if (connectorDetails.connectorPlugType === "ccs") {
      setPlugTypeIcon('/assets/images/ccs.svg');
    } else if (connectorDetails.connectorPlugType === "type_2_plug") {
      setPlugTypeIcon('/assets/images/type2.svg');
    } else if (connectorDetails.connectorPlugType === "chademo") {
      setPlugTypeIcon('/assets/images/chademo.svg');
    } else {
      setPlugTypeIcon('/assets/images/3pin.svg');
    }
  }, [connectorDetails]);

  return (
    <div className={'map-popup-container-connector ' + chargeStatusCssClass}>
      <div>
        <img className={'charge-status-type-icon'} src={plugTypeIcon} alt='Connector' />
        <span className={'charge-status-type-text'}>{connectorDetails.connectorPlugTypeName !== "undefined" ? connectorDetails.connectorPlugTypeName : "Unknown"}</span>
      </div>
      <div>
        <span><b>Speed</b></span>
        <span>{connectorDetails.connectorMaxChargeRate} kW</span>
      </div>
      <div>
        <span><b>Status</b></span>
        <div className={'charge-status-indicator'} title={connectorStatus.connectorStatus}></div>
      </div>
    </div>
  );
};

export default MapChargerPopup;
