import mapboxgl, { LngLatLike } from "mapbox-gl";

class MapboxHelper {

    // Handle map layer click events
    public static mapClickHandler(map: mapboxgl.Map, layers: string[], callback: (clickedFeatures: any) => void) {
        map.on('click', (event: any) => {
            // Find the clicked features
            let clickedFeatures = map.queryRenderedFeatures(event.point, {
                layers: layers
            });
            // Check if a feature was clicked
            if (clickedFeatures.length) {
                // center the map on the clicked feature
                if (clickedFeatures[0].geometry.type === 'Point') {
                    let offset = -window.innerHeight / 6;
                    if (window.innerHeight < 930) {
                        offset = -window.innerHeight / 2.5;
                    } else if (window.innerHeight < 1280) {
                        offset = -window.innerHeight / 4;
                    }
                    map.flyTo({
                        center: [clickedFeatures[0].geometry.coordinates[0], clickedFeatures[0].geometry.coordinates[1]],
                        offset: [0, offset],
                        zoom: 15
                    });
                };
                // find and remove duplicate features
                clickedFeatures = clickedFeatures.filter((feature, index, self) => {
                    return index === self.findIndex((feature2) => {
                        return feature.properties!.id === feature2.properties!.id;
                    });
                });
                // Run the callback function
                callback(clickedFeatures);
                console.log(clickedFeatures);
            } else {
                // If no feature was clicked, do nothing
                console.log('No feature found');
            }
        });
    }

    // Add a popup to the map
    private static popup: mapboxgl.Popup;
    public static addPopup(map: mapboxgl.Map, coordinates: LngLatLike, content: any) {
        // Create a new popup instance
        if (!this.popup) {
            this.popup = new mapboxgl.Popup({
                anchor: 'top',
                offset: [0, 10],
                className: 'map-popup',
                closeOnMove: false,
                closeButton: false,
                closeOnClick: true,
                maxWidth: 'none'
            });
            // Create event handler to remove popup on map drag
            map.on('dragstart', () => {
                this.popup.remove();
            })
            // Create event handler to remove popup on map zoom
            map.on('zoomstart', () => {
                this.popup.remove();
            })
        }

        // Set popup details
        this.popup.setLngLat(coordinates)
            .setDOMContent(content)
            .addTo(map);
    }

    public static addCircleLayer(map: mapboxgl.Map, name: string, sourceData: any, clustering?: boolean) {

        // Default clustering to true
        if (clustering === undefined) {
            clustering = true;
        }

        if (sourceData !== undefined && map !== undefined && map.getLayer(name + '-layer') === undefined) {
            // Add an image to use as a marker
            map.loadImage(
                'https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png',
                (error, image: any) => {
                    if (error) throw error;
                    map.addImage('custom-marker', image);
                    // Add the GeoJSON source
                    map.addSource(name, {
                        'type': 'geojson',
                        'data': sourceData,
                        cluster: clustering,
                        clusterMaxZoom: 12,
                        clusterRadius: 50,
                    });

                    // clustering circle layer
                    map.addLayer({
                        'id': name + '-cluster-circle-layer',
                        'type': 'circle',
                        'source': name,
                        'filter': ['has', 'point_count'],
                        'paint': {
                            'circle-opacity': 0.75,
                            'circle-color': [
                                'step',
                                ['get', 'point_count'],
                                '#51bbd6',
                                100,
                                '#f1f075',
                                750,
                                '#f28cb1'
                            ],
                            'circle-radius': [
                                'step',
                                ['get', 'point_count'],
                                20,
                                100,
                                30,
                                750,
                                40
                            ],
                            'circle-stroke-color': '#fff',
                            'circle-stroke-width': 2
                        }
                    });

                    // clustering text layer
                    map.addLayer({
                        'id': name + '-cluster-text-layer',
                        'type': 'symbol',
                        'source': name,
                        'filter': ['has', 'point_count'],
                        'layout': {
                            'text-field': '{point_count_abbreviated}',
                            'text-font': ['DIN Offc Pro Bold', 'Arial Unicode MS Bold'],
                            'text-size': 12
                        },
                        'paint': {
                            'text-color': '#fff'
                        }
                    });

                    // Add the symbol layer
                    map.addLayer({
                        'id': name + '-layer',
                        'type': 'circle',
                        'source': name,
                        'filter': ['!', ['has', 'point_count']],
                        'paint': {
                            'circle-opacity': 0.8,
                            'circle-color': '#4854e0',
                            'circle-radius': 8,
                            'circle-stroke-color': '#fff',
                            'circle-stroke-width': 2
                        }
                    });
                }
            );
        }

    }

    public static addSymbolLayer(map: mapboxgl.Map, name: string, sourceData: any, clustering?: boolean) {

        // Default clustering to true
        if (clustering === undefined) {
            clustering = true;
        }

        if (sourceData !== undefined && map !== undefined && map.getLayer(name + '-layer') === undefined) {
            // Add an image to use as a marker
            map.loadImage(
                'https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png',
                (error, image: any) => {
                    if (error) throw error;
                    map.addImage('custom-marker', image);
                    // Add the GeoJSON source
                    map.addSource(name, {
                        'type': 'geojson',
                        'data': sourceData,
                        cluster: clustering,
                        clusterMaxZoom: 14,
                        clusterRadius: 50,
                    });

                    // clustering circle layer
                    map.addLayer({
                        'id': name + '-cluster-circle-layer',
                        'type': 'circle',
                        'source': name,
                        'filter': ['has', 'point_count'],
                        'paint': {
                            'circle-opacity': 0.75,
                            'circle-color': [
                                'step',
                                ['get', 'point_count'],
                                '#51bbd6',
                                100,
                                '#f1f075',
                                750,
                                '#f28cb1'
                            ],
                            'circle-radius': [
                                'step',
                                ['get', 'point_count'],
                                20,
                                100,
                                30,
                                750,
                                40
                            ],
                            'circle-stroke-color': '#fff',
                            'circle-stroke-width': 2
                        }
                    });

                    // clustering text layer
                    map.addLayer({
                        'id': name + '-cluster-text-layer',
                        'type': 'symbol',
                        'source': name,
                        'filter': ['has', 'point_count'],
                        'layout': {
                            'text-field': '{point_count_abbreviated}',
                            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                            'text-size': 12
                        }
                    });

                    // Add the symbol layer
                    map.addLayer({
                        'id': name + '-layer',
                        'type': 'symbol',
                        'source': name,
                        'filter': ['!', ['has', 'point_count']],
                        'layout': {
                            'icon-image': 'custom-marker',
                            // get the title name from the source's "title" property
                            'text-field': ['get', 'title'],
                            'text-font': [
                                'Open Sans Semibold',
                                'Arial Unicode MS Bold'
                            ],
                            'text-offset': [0, 1.25],
                            'text-anchor': 'top'
                        }
                    });
                }
            );
        }

    }

}

export default MapboxHelper;